import { BtnHeader } from './MuiCustomized';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className='Header'>
      <Link className='Link' to='/'>
        <BtnHeader>Магазини</BtnHeader>
      </Link>
      <div className='Separator'></div>
      <Link className='Link' to='cart'>
        <BtnHeader>Кошик</BtnHeader>
      </Link>
    </div>
  );
}
