import { useState } from 'react';
import { Stack } from '@mui/material';
import { BtnShop, BtnAssortment, BtnClearCart } from './MuiCustomized.js';

export default function Shop({
  db,
  cart,
  handleAddToCart,
  setCart,
  createNotification,
}) {
  let cakes = db.filter((item) => item.shop === 'Cakes');
  let candies = db.filter((item) => item.shop === 'Candies');
  let gingerbread = db.filter((item) => item.shop === 'Gingerbread');
  let pastries = db.filter((item) => item.shop === 'Pastries');
  const [shop, setShop] = useState(cakes);

  function chooseShop(products) {
    setShop(products);
  }

  return (
    <div className='ShopContent'>
      <Shops
        chooseShop={chooseShop}
        cakes={cakes}
        candies={candies}
        gingerbread={gingerbread}
        pastries={pastries}
        cart={cart}
        setCart={setCart}
        createNotification={createNotification}
      />
      <Assortment shop={shop} handleAddToCart={handleAddToCart} />
    </div>
  );
}

function Shops({
  chooseShop,
  cakes,
  candies,
  gingerbread,
  pastries,
  cart,
  setCart,
  createNotification,
}) {
  return (
    <div className='ShopList'>
      <Stack alignItems='center' spacing='40px' direction='column'>
        <h2>Назва магазину:</h2>
        <BtnShop disabled={cart.length > 0} onClick={() => chooseShop(cakes)}>
          <b>Імперія тортів</b>
        </BtnShop>
        <BtnShop disabled={cart.length > 0} onClick={() => chooseShop(candies)}>
          <b>Ласощі</b>
        </BtnShop>
        <BtnShop
          disabled={cart.length > 0}
          onClick={() => chooseShop(gingerbread)}
        >
          <b>Імбирні пряники</b>
        </BtnShop>
        <BtnShop
          disabled={cart.length > 0}
          onClick={() => chooseShop(pastries)}
        >
          <b>Домашні тістечка</b>
        </BtnShop>
        <BtnClearCart
          disabled={cart.length === 0}
          onClick={() => {
            setCart([]);
            createNotification('cleared');
          }}
        >
          Очистити кошик
        </BtnClearCart>
      </Stack>
    </div>
  );
}

function Assortment({ shop, handleAddToCart }) {
  return (
    <>
      <div className='AssortmentContent'>
        {shop.map((item) => (
          <div className='Item' key={item.id}>
            <img
              className='AssortmentItemImage'
              src={item.image}
              alt={item.product}
              width='100%'
              height='100%'
            />
            <div>
              <span className='ItemInfo'>
                <b> {item.product}</b>
                <br />
                {item.cost + '₴'}
              </span>
              <BtnAssortment onClick={() => handleAddToCart(item)}>
                Додати в кошик
              </BtnAssortment>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
