import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/material';
import useLocalStorageState from '../hooks/useLocalStorageState';
import {
  BtnSubmitOrder,
  InputUserInfo,
  BtnCartCleaning,
} from './MuiCustomized.js';
import CartItems from './CartItems';

export default function Cart({
  cart,
  setCart,
  handleAddToCart,
  handleDecreaseQuantity,
  createNotification,
}) {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [order, setOrder] = useLocalStorageState('order');

  let total = 0;

  function getOrder() {
    setOrder([
      ...order,
      {
        id: uuidv4(),
        customer_name: userName,
        customer_email: userEmail,
        customer_phone: userPhone,
        customer_address: userAddress,
        ordered_items: cart,
        order_total: CartTotalPrice(),
      },
    ]);
    setUserName('');
    setUserEmail('');
    setUserPhone('');
    setUserAddress('');
  }

  function CartTotalPrice() {
    if (cart) {
      cart.forEach((item) => {
        if (item.cartQuantity) {
          total += item.cost * item.cartQuantity;
        }
      });
      return total + '₴';
    } else return '0';
  }

  function handleChangeQuantity(item, e) {
    let changed = cart.map((cartItem) => {
      if (cartItem.id === item.id) {
        if (e.target.value >= 10) {
          return { ...cartItem, cartQuantity: 10 };
        } else if (e.target.value <= 1) {
          return { ...cartItem, cartQuantity: 1 };
        } else {
          return { ...cartItem, cartQuantity: e.target.value };
        }
      } else return cartItem;
    });
    setCart(changed);
  }

  return (
    <div className='CartContent'>
      <Stack
        className='UserInfo'
        alignItems='center'
        spacing='50px'
        direction='column'
      >
        <InputUserInfo
          text='Ім&rsquo;я'
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <InputUserInfo
          text='Email'
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <InputUserInfo
          text='Телефон'
          value={userPhone}
          onChange={(e) => setUserPhone(e.target.value)}
        />
        <InputUserInfo
          text='Адреса'
          value={userAddress}
          onChange={(e) => setUserAddress(e.target.value)}
          // disabled={false}
        />
      </Stack>
      <div className='CartSection'>
        <div className='Cart'>
          <CartItems
            cart={cart}
            setCart={setCart}
            handleChangeQuantity={handleChangeQuantity}
            handleAddToCart={handleAddToCart}
            handleDecreaseQuantity={handleDecreaseQuantity}
            createNotification={createNotification}
          />
        </div>
        <div className='CartTotalSection'>
          <div className='CartTotalPrice'>
            <p>Загальна сума: {CartTotalPrice()}</p>
          </div>
          <BtnCartCleaning
            disabled={cart.length < 1}
            onClick={() => {
              setCart([]);
              createNotification('cleared');
            }}
          >
            Очистити кошик
          </BtnCartCleaning>
          <BtnSubmitOrder
            disabled={
              cart.length < 1 ||
              userName === '' ||
              userPhone === '' ||
              userEmail === '' ||
              userAddress === ''
            }
            onClick={() => {
              getOrder();
              setCart([]);
              createNotification('ordered');
            }}
          >
            Замовити
          </BtnSubmitOrder>
        </div>
      </div>
    </div>
  );
}

// function CartItems({
//   cart,
//   setCart,
//   handleChangeQuantity,
//   handleAddToCart,
//   handleDecreaseQuantity,
//   createNotification,
// }) {
//   return cart.length > 0 ? (
//     cart.map((item) => {
//       return (
//         <div className='CartItem' key={item.id}>
//           <img
//             src={item.image}
//             alt={item.name}
//             width='325px'
//             height='300px'
//             style={{ overflow: 'hidden' }}
//           />
//           <div className='CartItemDescription'>
//             <div className='CartItemName'>
//               <b>{item.product}</b>
//             </div>
//             <div className='CartItemCost'>
//               Price: {item.cost * item.cartQuantity}₴
//             </div>
//             <div className='CartItemQuantity'>
//               <InputCartQuantity
//                 value={item.cartQuantity}
//                 onChange={(e) => handleChangeQuantity(item, e)}
//               ></InputCartQuantity>
//               <div className='CartQuantityButtons'>
//                 <Stack direction='column'>
//                   <BtnIncrease
//                     onClick={() => handleAddToCart(item)}
//                   ></BtnIncrease>
//                   <BtnDecrease
//                     onClick={() => handleDecreaseQuantity(item)}
//                   ></BtnDecrease>
//                 </Stack>
//                 <BtnDelete
//                   onClick={() => {
//                     setCart(cart.filter((cartItem) => cartItem.id !== item.id));
//                     createNotification('removed', item);
//                   }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     })
//   ) : (
//     <h1 className='EmptyCart'>Ваш кошик порожній</h1>
//   );
// }
