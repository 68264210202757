import { NotificationManager } from 'react-notifications';

export function createNotification(type, name) {
  switch (type) {
    case 'added':
      NotificationManager.success(``, `Додано ${name.product} до кошика`);
      break;

    case 'removed':
      NotificationManager.error(
        '',
        `Видалено ${name.product} з кошика`,
        1000,
        () => {
          alert('callback');
        }
      );
      break;

    case 'ordered':
      NotificationManager.success(``, `Замовлення оформлено!`);
      break;

    case 'cleared':
      NotificationManager.error(``, 'Кошик очищено');
  }
}
