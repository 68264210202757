import { Stack } from '@mui/material';
import {
  BtnIncrease,
  BtnDecrease,
  InputCartQuantity,
  BtnDelete,
} from './MuiCustomized.js';

export default function CartItems({
  cart,
  setCart,
  handleChangeQuantity,
  handleAddToCart,
  handleDecreaseQuantity,
  createNotification,
}) {
  return cart.length > 0 ? (
    cart.map((item) => {
      return (
        <div className='CartItem' key={item.id}>
          <img
            src={item.image}
            alt={item.name}
            width='325px'
            height='300px'
            style={{ overflow: 'hidden' }}
          />
          <div className='CartItemDescription'>
            <div className='CartItemName'>
              <b>{item.product}</b>
            </div>
            <div className='CartItemCost'>
              Price: {item.cost * item.cartQuantity}₴
            </div>
            <div className='CartItemQuantity'>
              <InputCartQuantity
                value={item.cartQuantity}
                onChange={(e) => handleChangeQuantity(item, e)}
              ></InputCartQuantity>
              <div className='CartQuantityButtons'>
                <Stack direction='column'>
                  <BtnIncrease
                    onClick={() => handleAddToCart(item)}
                  ></BtnIncrease>
                  <BtnDecrease
                    onClick={() => handleDecreaseQuantity(item)}
                  ></BtnDecrease>
                </Stack>
                <BtnDelete
                  onClick={() => {
                    setCart(cart.filter((cartItem) => cartItem.id !== item.id));
                    createNotification('removed', item);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    })
  ) : (
    <h1 className='EmptyCart'>Ваш кошик порожній</h1>
  );
}
