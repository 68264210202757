import { items } from './config/db.js';
import { theme } from './components/MuiCustomized.js';
import { ThemeProvider } from '@mui/system';
import { Routes, Route } from 'react-router-dom';
import useLocalStorageState from './hooks/useLocalStorageState';
import { createNotification } from './utils/functions';
import { NotificationContainer } from 'react-notifications';
import Shop from './components/Shop';
import Cart from './components/Cart';
import Header from './components/Header';

import './App.css';
import 'react-notifications/lib/notifications.css';

export default function App() {
  let assortment = items;

  const [cart, setCart] = useLocalStorageState('cart');

  function handleAddToCart(item) {
    let foundInCart = cart
      ? cart.find((cartItem) => cartItem.id === item.id)
      : false;
    if (foundInCart) {
      let nextCart = cart.map((item) => {
        if (item.id === foundInCart.id) {
          if (foundInCart.cartQuantity >= 10) {
            return {
              ...foundInCart,
              cartQuantity: Number(10),
            };
          } else {
            return {
              ...foundInCart,
              cartQuantity: Number(foundInCart.cartQuantity) + 1,
            };
          }
        } else return item;
      });
      setCart(nextCart);
    } else {
      setCart([...cart, { ...item, cartQuantity: 1 }]);
      createNotification('added', item, foundInCart);
    }
  }

  function handleDecreaseQuantity(item) {
    if (item.cartQuantity > 1) {
      let decreased = cart.map((cartItem) => {
        if (cartItem.id === item.id) {
          return {
            ...cartItem,
            cartQuantity: Number(cartItem.cartQuantity) - 1,
          };
        } else return cartItem;
      });
      setCart(decreased);
    } else {
      setCart(cart.filter((item) => item.id === item.id));
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Routes>
        <Route
          path='/'
          element={
            <Shop
              cart={cart}
              setCart={setCart}
              db={assortment}
              handleAddToCart={handleAddToCart}
              createNotification={createNotification}
            />
          }
        />
        <Route
          path='cart'
          element={
            <Cart
              cart={cart}
              setCart={setCart}
              handleAddToCart={handleAddToCart}
              handleDecreaseQuantity={handleDecreaseQuantity}
              createNotification={createNotification}
            />
          }
        />
      </Routes>
      <NotificationContainer />
    </ThemeProvider>
  );
}
